<template>
  <div class="d-flex">
    <auto-suggest
      v-bind="$attrs"
      class="flex-grow-1"
      :icon="icon"
      :showAddButton="false"
      v-model="companyVehicle"
      @search="lookupLicensePlate"
      @selected="selected"
      suggestionText="name"
      suggestionValue="id"
      return-object
      :label="searchFieldLabel"
    />
    <v-btn
      v-if="allowAddVehicle"
      :to="{ name: 'companyVehicleCreate' }"
      color="primary"
      class="flex-shrink-1 mt-3 mx-2"
      icon
    >
      <v-icon>mdi-truck-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import AutoSuggest from "../common/AutoSuggest.vue";
import {searchCompanyVehicles} from '@/services/companyVehicle.js'

export default {
  props: {
    value: {
      type: Object,
      required: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-text-box-search-outline',
    },
    label: {
      type: String,
      required: false,
    },
    allowAddVehicle: {
      type: Boolean,
      required: false,
      default: true
    },
    companyType: {
      type: String,
      required: false,
      default: 'SUPPLIER'
    }
  },
  data: () => ({
    companyVehicle: null,
    searchFieldLabel: null
  }),
  components:{
      AutoSuggest
  },
  watch: {
    companyVehicle(val) {
      console.log("vehicle changed: ", val);
      this.$emit("input", val)
    },
  },
  mounted() {
    if(this.label){
      this.searchFieldLabel = this.label;
    }
    else{
      this.searchFieldLabel = this.$vuetify.lang.t('$vuetify.worksheet.lookupVehicle');
    }
  },
  methods: {
    lookupLicensePlate(query, callback) {
      const params = {
        page: 0,
        size: 100,
        sort: "vehicleLicensePlate",
        searchTerms: query,
        companyType: this.companyType
      };
      searchCompanyVehicles(params)
        .then((response) => {
          if (response && response.data) {
            const vehicles = response.data.content;
            if (vehicles && vehicles.length > 0) {
              vehicles.forEach((v) => {
                v.name = `${v.vehicleLicensePlate} - ${v.companyName}`;
              });
            }
            callback(vehicles);
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
          callback([]);
        });
    },
    selected(){
      this.$emit("input", this.companyVehicle);
      this.$emit("selected", this.companyVehicle);
    },
  },
};
</script>

<style></style>
